.App {
  text-align: left;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}
button.social-icon {
  color: white;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  border: none;
}

.facebook {
  background-color: #4267B2;
}
.google {
  background-color: #DB4437;
}
.twitter {
  background-color: #1DA1F2;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
body {
  margin-bottom: 60px;
  font-family: 'Montserrat', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 30px; /* Set the fixed height of the footer here */
  line-height: 30px; /* Vertically center the text there */
}
.hangingindent {
  padding-left: 22px ;
  text-indent: -19px ;
}

